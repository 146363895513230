body {
  color: #fff;
  overflow-x: hidden;
  height: 100%;
  background-color: #cfd8dc;
  background-repeat: no-repeat;
}

.card {
  background-image: url("https://picsum.photos/1200/600?random=1");
  background-size: cover;
  width: 600px;
  height: 350px;
  border-radius: 20px;
  box-shadow: 0px 8px 16px 4px #9e9e9e;
  margin-top: 50px;
  margin-bottom: 50px;
}

.time-font {
  font-size: 50px;
}

.sm-font {
  font-size: 18px;
}

.med-font {
  font-size: 28px;
}

.large-font {
  font-size: 60px;
}
